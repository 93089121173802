import './App.css';

const ExternalLink = ({ url, text }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: 'yellow' }}
  >
    {text}
  </a>
);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ color: '#b08bff' }}>We'll be back...</h1>
        <p style={{ padding: '10px' }}>
          Fusha was an Arabic class held at&nbsp;
            <ExternalLink
              url="http://icnyu.org"
              text="NYU"
            />
            &nbsp;from 2015-2018
          taught by&nbsp;
            <ExternalLink
              url="http://ammaristotle.com"
              text="Ammar Khan"
            />.
           <br />
           <br />
           Helpful old links for previous students:
        </p>
        <ul style={{ marginTop: 0 }}>
          • <ExternalLink url="http://class.arabicnyc.com" text="Class materials" />
          <br />
          • <ExternalLink url="http://learn.arabicnyc.com" text="Online exercises" />
        </ul>
      </header>
      <p style={{ color: 'gray' }}>
        &nbsp;Built with ♥️ in SF, CA
      </p>
    </div>
  );
}

export default App;
